import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { FaSave, FaSpinner, FaStar, FaTrashAlt } from "react-icons/fa";
import ObjectiveStateSelector from "../pages/ObjectiveStateSelector";

const OBJECTIVE_ACTION = gql`
  mutation AddObjective(
    $text: String!
    $stateId: ID
    $type: String!
    $primary: Boolean
    $forUser: ID
    $forTeam: ID
    $id: ID
    $deleted: Boolean
  ) {
    addObjective(
      id: $id
      text: $text
      stateId: $stateId
      type: $type
      primary: $primary
      forUser: $forUser
      forTeam: $forTeam
      deleted: $deleted
    ) {
      success
      created
      message
      id
    }
  }
`;

export const OBJECTIVE_STATES_QUERY = gql`
  query myQuery {
    states: objectiveStates {
      id
      text
      color
      textColor
    }
  }
`;

type ObjectiveInputLineProps = {
  okr: (OKR & { deleted?: boolean }) | null;
  user: ID;
  create?: boolean;
  mobile?: boolean;
  onUpdate?: Function;
};

export default function ObjectiveInputLine({
  okr: okrProps,
  user,
  create,
  onUpdate,
  mobile,
}: ObjectiveInputLineProps) {
  const [addObjective, { loading }] = useMutation(OBJECTIVE_ACTION);

  const [okr, setOkr] = useState(okrProps);
  const { data: statesData } = useQuery(OBJECTIVE_STATES_QUERY);

  function send(okrP?: OKR & { deleted?: boolean }) {
    const okrT = okrP || okr;
    if (okrT) {
      addObjective({
        variables: {
          text: okrT.text,
          type: "user",
          stateId: (okrT.stateObj || statesData?.states?.[0]).id,
          primary: okrT.primary,
          forUser: user,
          deleted: okrT.deleted,
          id: okrT.id,
        },
      })
        .then(({ data }) => {
          if (!okrT?.id) {
            update("id", data.addObjective.id);
            okrT.id = data.addObjective.id;
            setOkr({ primary: false, reviews: [], text: '', type: 'INDIVIDUAL' });
          }
          onUpdate && onUpdate(okrT);
        })
        .catch((e) => alert(e.message));
    }
  }

  function update(field: string, value: any, needSave: boolean = false) {
    const res = setOkr({ ...okr, [field]: value } as OKR);
    if (needSave) send({ ...okr, [field]: value } as OKR);
    return res;
  }

  function deleteObj() {
    if (window.confirm("Are you sure you want to delete this objective ?"))
      update("deleted", true, true);
  }

  if (mobile) {
    return (
      <div className="flex flex-1">
        <div className={`pt-3 flex-1 ${okr?.primary ? "font-medium" : ""} `}>
          <div className="flex flex-1 items-center">
            <FaStar
              className={`cursor-pointer inline mr-2 ${
                okr?.primary
                  ? "text-orange-300"
                  : "text-gray-300 hover:text-orange-200"
              } `}
              onClick={() => update("primary", !okr?.primary, !!okr?.id)}
              title="Primary objective: This objective helps to achieve your KPI"
            />
            <input
              placeholder="Create new objective"
              value={okr?.text || ""}
              className={`m-0 flex-1 px-1 bg-transparent ${okr?.primary ? "font-medium" : ""}`}
              onChange={(e) => update("text", e.target.value)}
              onBlur={() => send()}
            />

            <FaSpinner
              className={`text-xl text-gray-400 inline animate-spin mx-4 ${
                loading ? "visible" : "invisible"
              }`}
            />
          </div>
        </div>
        <div className="text-center py-2">
          <ObjectiveStateSelector
            states={statesData?.states}
            value={okr?.stateObj || statesData?.states?.[0]}
            onChange={(state) => update("stateObj", state, !!okr?.id)}
          />
        </div>

        <div className="text-center py-2 space-x-4">
          <div className=" invisible hover:visible">
            {!!okr?.id ? (
              <>
                {/*<FaUserPlus className="cursor-pointer text-xl text-gray-400 inline" />*/}
                <FaTrashAlt
                  className="cursor-pointer text-xl text-gray-400 inline"
                  onClick={deleteObj}
                />
              </>
            ) : (
              <FaSave
                className="cursor-pointer text-xl text-gray-400 inline"
                onClick={() => send()}
              />
            )}
          </div>
        </div>
      </div>
    );

  }

  return (
    <>
      <td className={`pt-3 ${okr?.primary ? "font-medium" : ""} `}>
        <div className="flex flex-1 items-center">
          <FaStar
            className={`cursor-pointer inline mr-2 ${
              okr?.primary
                ? "visible text-orange-300"
                : "invisible text-gray-300"
            } group-hover:visible`}
            onClick={() => update("primary", !okr?.primary, !!okr?.id)}
            title="Primary objective: This objective helps to achieve your KPI"
          />
          <input
            placeholder="Create new objective"
            value={okr?.text || ""}
            className={`m-0 flex-1 px-1 ${okr?.primary ? "font-medium" : ""}`}
            onChange={(e) => update("text", e.target.value)}
            onBlur={() => send()}
          />

          <FaSpinner
            className={`text-xl text-gray-400 inline animate-spin mx-4 ${
              loading ? "visible" : "invisible"
            }`}
          />
        </div>
      </td>
      <td className="text-center py-2">
        <ObjectiveStateSelector
          states={statesData?.states}
          value={okr?.stateObj || statesData?.states?.[0]}
          onChange={(state) => update("stateObj", state, !!okr?.id)}
        />
      </td>

      <td className="text-center py-2 space-x-4">
        <div className=" invisible group-hover:visible">
          {!!okr?.id ? (
            <>
              {/*<FaUserPlus className="cursor-pointer text-xl text-gray-400 inline" />*/}
              <FaTrashAlt
                className="cursor-pointer text-xl text-gray-400 inline"
                onClick={deleteObj}
              />
            </>
          ) : (
            <FaSave
              className="cursor-pointer text-xl text-gray-400 inline"
              onClick={() => send()}
            />
          )}
        </div>
      </td>
    </>
  );
}
