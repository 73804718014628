import { ReactNode, useEffect, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  title: string;
  children: ReactNode;
  onClose?: Function;
  closeFunction?: Function;
  autoHeight?: boolean;
  cannotClose?: boolean;
  className?: string;
}

function Modal({ title, children, autoHeight, closeFunction, cannotClose, className, onClose }: ModalProps) {
  const navigate = useNavigate();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeFunction ? closeFunction( ) : navigate(-1);
    }
  }, [navigate, closeFunction]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      role="button"
      className="fixed top-0 left-0 right-0 bottom-0 bg-gray-400 bg-opacity-80 z-20 p-0 lg:p-10"
      onClick={() => closeFunction ? closeFunction() : navigate(-1)}
    >
      <div
        role="button"
        className={`bg-white lg:w-2/3 h-full lg:h-auto lg:max-h-full ${autoHeight ? '' : 'lg:h-5/6'} m-auto p-4 lg:p-8 py-8 lg:py-6  rounded shadow-md cursor-default flex flex-col lg:justify-start ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex w-full">
          <div className="flex flex-auto font-bold text-2xl py-8 lg:py-0 self-center text-title">
            {title}
          </div>
          {!cannotClose && (
            <div
              role="button"
              className="text-center"
              onClick={() => closeFunction ? closeFunction() : navigate(-1)}
            >
              <FaTimes />
            </div>
          )}
        </div>
        <div className="overflow-auto mb-0 mt-4 pr-2">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
